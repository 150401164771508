@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Nunito Sans Light"), local("NunitoSans-Light"),
    url("https://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8WAc5tU1E.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif !important;
}

h1 {
  font-size: 4vmin;
  text-align: center;
  margin: 2vmin;
  line-height: 1.2;
}

button {
  font-family: "Nunito Sans", sans-serif !important;
}

canvas {
  box-shadow: 1vmin 1vmin 2vmin 0 hsla(0, 0, 0, 0.5);
}

aside .ant-layout {
  height: 100vh;
  background-color: #fff;
}

.ant-layout-footer {
  background-color: #fff;
  text-align: center;
  padding: 1vmin 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.sider {
  align-items: center;
}

.crate-picker {
  justify-content: space-evenly;
  width: 80%;
}

.ant-collapse {
  width: 80%;
  margin: 2vh 0;
}

.ant-collapse-content {
  max-height: 50vh;
  overflow: scroll;
}

.ant-btn-link {
  padding: 0;
  height: auto;
}

.dependency-tag {
  cursor: pointer;
}

.crate-panel-list {
  line-height: 2;
}

.crate-config-item {
  margin-bottom: 2vh;

  .ant-select-auto-complete {
    min-width: 80%;
  }
}

.ant-checkbox-group {
  @extend .column;

  padding: 0 10%;
}

.crate-list-button {
  cursor: pointer;
  padding: 0 24px;
  margin: 0;
  background-color: #fff;
  height: auto;
  white-space: normal;
}

.ant-list-item-meta-description {
  text-align: left;
}

.crate-row {
  justify-content: space-between;
}

.footer {
  justify-content: center;
  top: 0;
  padding: 2vh 0;
}

.content {
  overflow: hidden;
}

.dependency-graph-container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
